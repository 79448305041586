import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import iconAdv1 from "../images/VirtualConcept/icon-adv-1.png";
import iconAdv2 from "../images/VirtualConcept/icon-adv-2.png";
import iconAdv3 from "../images/VirtualConcept/icon-adv-3.png";
import ContactForm from "../components/contactForm/form";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import Seo from "../components/seo";
import SingleDevice from "../components/singleDevice/singleDevice";
import Testimonials from "../components/testimonials/testimonials";
import Top from "../components/top/top";

import testimonialsState from "./../utils/testimonials";

// markup
const VirtualConceptPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 1,
    scrollToForm: scrollToForm,
    title: (
      <span style={{ fontSize: "44px" }}>
        Manage both your Restaurant and Virtual
        <br /> Concepts from one dashboard
      </span>
    ),
    subtitle: `Virtual Concept integration from Orders.co allows restaurants to consolidate all orders,
            menu management and analytics into one place. Eliminating the need for excess tablets and
            increasing productivity.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        // alt="menu management"
        // src="../images/VirtualConcept/menu-management.png"
        alt="Q-commerce"
        src="../images/VirtualConcept/Q-commerce.png"
        className="mw-100"
      />
    ),
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "One Device",
      description: `Free staff from the stress of managing multiple tablets with Orders.co consolidation.
                All online ordering platforms in one user-friendly device.`,
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "One Order List",
      description: `Make servicing hours a breeze with all brands, concepts, and platforms in one unified ordering list.`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Reporting",
      description: `Use tools like top selling items, sales by provider,
                and summary reports to make data-driven decision and optimize all online ordering channels.`,
    },
  ];

  const singleDeviceProps = {
    title: `Easily manage your restaurant's online orders with a single device`,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Restaurant Menu Management"
          src="../images/menu-management_tablet.png"
          className="mw-100"
        />
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const testimonialsProps = {
    layout: 1,
    items: [
      testimonialsState.natalies_peruvian,
      // testimonialsState.slash_pizza,
      testimonialsState.c_viche,
      testimonialsState.jonahs_kabob,
    ],
    title: "Hear From Our Customers",
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Stress-Free Order Management",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Free yourself from the stress of managing incoming orders across multiple tablets with
            Orders.co’s integration system. Consolidate all of your online orders into one easy dashboard.
            Freeing up your staff and counter space.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="order management"
        src="../images/VirtualConcept/Stress-Free Order Management.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "equal-big",
    title: "A Team Behind You",
    titleStyle: "smaller",
    imagePosition: "left",
    description: `Access our 24/7 expert support team for all your technical, menu, and optimization needs.
            Our experienced staff can help make your day-to-day easier, while increasing your revenue.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="restaurant"
        src="../images/VirtualConcept/restaurant.png"
      />
    ),
  };
  const imageTextProps3 = {
    layout: "equal-big",
    title: "15 Minute Onboarding",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Schedule a quick 15 minute phone call to give our integration team the necessary
            information, then sit back and let them take over. We provide regular updates on the status of
            your onboarding and will do all the heavy lifting for you.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="3rd party integration data"
        src="../images/VirtualConcept/integration.png"
      />
    ),
  };

  const questions = [
    {
      question: `How much does Orders.co setup cost?`,
      answer: `Orders.co setup fee is $100.`,
    },
    {
      question: `How long does it take to set up Orders.co?`,
      answer: `Orders.co’s integration and setup takes around 1-2 weeks, depending on the current workload.
                For a more detailed estimate, contact a sales representative.`,
    },
    {
      question: `How will orders from multiple virtual stores be displayed in Orders.co?`,
      answer: `All your integrated virtual stores are shown together in the Orders.co “Orders” list.
                Next to each order is the Brand Name and Platform, so staff can easily identify every order.`,
    },
    {
      question: `Which delivery platforms are supported by Orders.co?`,
      answer: `Currently, Orders.co supports Grubhub, Postmates, Uber Eats, and DoorDash.
                Many more are on the way, but Orders.co partners are encouraged to request new integrations.`,
    },
    {
      question: `How can we integrate an Orders.co ordering site into our existing website?`,
      answer: `An Orders.co ordering site can be easily integrated into your existing website through
                the addition of an “Order Now” button. Customers can click this button and quickly be
                redirected to place their order.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Virtual Concept"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <div
          className="bg-mobile mt-xl-0 pt-xl-0"
          style={{
            backgroundColor: "#F7F7F7",
            marginTop: "-80px",
            paddingTop: "40px",
          }}
        >
          <Features items={features} title="Top Benefits" />
        </div>
        <SingleDevice {...singleDeviceProps} />
        <Testimonials {...testimonialsProps} />
        <ImageText {...imageTextProps1} />
        <ImageText {...imageTextProps2} />
        <ImageText {...imageTextProps3} />
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <Integrations scrollToForm={scrollToForm}></Integrations>
          <Faq items={questions} />
          <div ref={formRef}>
            <ContactForm
              title={
                <>
                  Manage And
                  <br /> Optimize Delivery
                  <br /> With Orders.co
                </>
              }
            ></ContactForm>
          </div>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "virtual-concept" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default VirtualConceptPage;
